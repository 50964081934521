// extracted by mini-css-extract-plugin
export const navWrapper = "PlasmicNavbar-module--navWrapper--2m6iX";
export const navContainer = "PlasmicNavbar-module--navContainer--p1UF9";
export const navLeft = "PlasmicNavbar-module--navLeft--1rW8C";
export const img = "PlasmicNavbar-module--img--1Wo7r";
export const navRight = "PlasmicNavbar-module--navRight--2wWSz";
export const features = "PlasmicNavbar-module--features--1DKU8";
export const exchanges = "PlasmicNavbar-module--exchanges--4f-ZL";
export const download = "PlasmicNavbar-module--download--1zuXT";
export const pricing = "PlasmicNavbar-module--pricing--2zelO";
export const resources = "PlasmicNavbar-module--resources--y2B-Y";
export const navButtons = "PlasmicNavbar-module--navButtons--1HPlM";
export const logIn = "PlasmicNavbar-module--logIn--2brDm";
export const tryForFree = "PlasmicNavbar-module--tryForFree--3MbFe";
export const menuButton___2G7Ni = "PlasmicNavbar-module--menuButton___2G7Ni--sihNC";
export const menuButton__tRbg = "PlasmicNavbar-module--menuButton__tRbg--1E9k4";