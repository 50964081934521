// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type CaretDownIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function CaretDownIcon(props: CaretDownIconProps) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        d={
          "M8.292 10.293a1.01 1.01 0 000 1.419l2.939 2.965a1.11 1.11 0 00.779.322c.279 0 .556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.99.99 0 00-.322-.218.979.979 0 00-.762 0 .99.99 0 00-.322.218l-2.298 2.317-2.307-2.327A.99.99 0 008.995 10a.99.99 0 00-.703.293z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  )
}

export default CaretDownIcon
/* prettier-ignore-end */
